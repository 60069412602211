import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Constants } from '../constants/constants';

const KEYS = Constants.key;

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  
  constructor() { }
 
  encryptUsingAES256(value: string) {
    let encrypted = CryptoJS.AES.encrypt(value.trim(), KEYS.trim());
    return encrypted.toString();
  }

  decryptUsingAES256(cipherText: string) {
    let decrypted = CryptoJS.AES.decrypt(cipherText.trim(), KEYS.trim()).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

}