import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

//app imports
import { LoginService } from "../services/login.service";
import { EncrDecrService } from "../services/encryptDecrypt.service";
import { HttpService } from '../services/http.service';
import { UserService } from '../services/user.service';
import { HelperService } from '../services/helper.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  validateForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private loginService: LoginService,
    private helperService: HelperService,
    private userService: UserService,
    private httpService: HttpService,
    private encrDecrService: EncrDecrService
  ) {}

  ngOnInit(): void {
    this.validateForm = this.formBuilder.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });
  }

  submitForm(): void {
    let userName = this.validateForm.getRawValue().userName;
    let password = this.validateForm.getRawValue().password;
    if(!userName) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
      alert('User name cannot be empty')
    } else if (!password) {
      alert('Password cannot be empty')
    } else{
      this.helperService.isLoading(true);
      let params = {
        userName: userName.toLowerCase()
      }
      this.httpService.get('/app/login', params)
      .subscribe((result) => {
        this.helperService.isLoading(false);
          if(result['status'] == "success") {
            this.checkLoginDetails(result['value'], password);
          } else if (result['status'] == "error") {
            console.log(result['value'])
            alert('Some error in verifying credentials');
          } else {
            alert('Contact your developer. There is an error.')
          }
        });
    }
  }

  checkLoginDetails(result, password) {
    if(result.length) {
      let decrypt = this.encrDecrService.decryptUsingAES256(result[0].password.toString());
      if(decrypt === password) {
        this.setUserDetails(result[0]);
        this.loginService.login();
      } else {
        alert('Invalid Password')
      }
    } else {
      alert('Invalid User Name')
    }
  }

  setUserDetails(result) {
    let encryptedDetails = this.encrDecrService.encryptUsingAES256(JSON.stringify(result));
    localStorage.setItem('spmUser', encryptedDetails);
    this.userService.setUserDetails();
  }
  

}
