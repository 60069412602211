  <div class="login-container">
    <div class="login-content">
      <div class="login-title">STUDENT PACKERS & MOVERS
      </div>
      <form nz-form [formGroup]="validateForm" class="login-form" >
        <!--(ngSubmit)="submitForm()"-->
        
          <nz-form-item>
            <nz-form-control>
              <nz-input-group [nzPrefix]="prefixUser">
                <input type="text" nz-input formControlName="userName" placeholder="Username" />
              </nz-input-group>
              <nz-form-explain *ngIf="validateForm.get('userName')?.dirty && validateForm.get('userName')?.errors"
                >Please input your username!</nz-form-explain
              >
            </nz-form-control>
          </nz-form-item>
          
          <nz-form-item>
            <nz-form-control>
              <nz-input-group [nzPrefix]="prefixLock">
                <input type="password" nz-input formControlName="password" placeholder="Password" />
              </nz-input-group>
              <nz-form-explain *ngIf="validateForm.get('password')?.dirty && validateForm.get('password')?.errors"
                >Please input your Password!</nz-form-explain
              >
            </nz-form-control>
          </nz-form-item>
          
          <nz-form-item>
            <nz-form-control>
              <!-- <a class="login-form-forgot" class="login-form-forgot">Forgot password</a> -->
              <button nz-button class="login-form-button" [nzType]="'primary'" (click)="submitForm()" >Log in</button>
              <!-- Or
              <a href="">register now!</a> -->
            </nz-form-control>
          </nz-form-item>
          
        </form>
    </div>
  </div>
    <ng-template #prefixUser><i nz-icon nzType="user"></i></ng-template>
    <ng-template #prefixLock><i nz-icon nzType="lock"></i></ng-template>
  
