import { Injectable, OnInit } from '@angular/core';
import { EncrDecrService } from './encryptDecrypt.service';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class UserService {
    currentUser: BehaviorSubject<any> = new BehaviorSubject({});

    constructor( private encrDecrService: EncrDecrService, loginService: LoginService, router: Router ) {
        if(localStorage.getItem('spmUser')) {
            this.setUserDetails();
        } else {
            loginService.logout();
        }
    }

    setUserDetails() {
        let cipherText = localStorage.getItem('spmUser');
        let data = this.encrDecrService.decryptUsingAES256(cipherText);
        let userDetails = JSON.parse(data);
        this.currentUser.next(userDetails);
    }


}