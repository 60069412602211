<nz-layout class="layout" *ngIf="isLoggedIn" >
  <nz-sider>
    <div class="logo">
      <img id="logoImg" src="/assets/logo/spmLogo.png" alt="" />
    </div>
    <ul nz-menu nzMode="inline">
        <li *ngIf="isAdmin()" (click)="setTitle('DASHBOARD')" nz-menu-item>
          <a routerLink="/dashboard" >
            <span nz-icon nzType="dashboard" nzTheme="outline"></span>
            <span>Dashboard</span>
          </a>
        </li>

        <li (click)="setTitle('CALENDAR')" nz-menu-item>
          <a routerLink="/calendar" >
            <span nz-icon nzType="calendar" nzTheme="outline"></span>
            <span>Calendar</span>
          </a>
        </li>

        <li nz-submenu nzTitle="Followup" nzIcon="check-circle">
          <!-- <ul>
            <li nz-menu-item (click)="title = 'FEEDBACK'" ><a routerLink="/followup/feedback" >Feedback</a></li>
          </ul> -->
          <ul>
            <li nz-menu-item (click)="setTitle('BOX COLLECTION')" ><a routerLink="/followup/boxCollection" >Box Collection</a></li>
            <li nz-menu-item (click)="setTitle('PAYMENT DETAILS')" ><a routerLink="/followup/paymentDetailsReport" >Payment Details</a></li>
          </ul>
        </li>

        <li nz-submenu *ngIf="isAdmin()" nzTitle="Users" nzIcon="user">
            <!-- <i nz-icon nzType="customer-service" nzTheme="outline"></i> -->
          <ul>
            <li nz-menu-item (click)="setTitle('ADD USERS')" ><a routerLink="/users/addUsers" >Add</a></li>
            <li nz-menu-item (click)="setTitle('ADD USERS')" ><a routerLink="/users/usersList" >List</a></li>
          </ul>
        </li>
    
        <li nz-submenu nzOpen nzTitle="Customers" nzIcon="usergroup-add" >
            <!-- <i nz-icon nzType="user" nzTheme="outline"></i> -->
          <ul>
            <li nz-menu-item (click)="setTitle('ADD CUSTOMERS')" ><a routerLink="/customers/addCustomers" >Add</a></li>
            <li nz-menu-item (click)="setTitle('SEARCH CUSTOMERS')" ><a routerLink="/customers/searchCustomers" >Search</a></li>
            <li nzSelected nz-menu-item (click)="setTitle('PENDING CUSTOMERS')" ><a routerLink="/customers/pendingCustomers" >Pending</a></li>
            <li nz-menu-item (click)="setTitle('QUOTATION CUSTOMERS')" ><a routerLink="/customers/quotationCustomers" >Quotation</a></li>
            <li nz-menu-item (click)="setTitle('CONFIRMED CUSTOMERS')" ><a routerLink="/customers/confirmedCustomers" >Confirmed</a></li>
            <li nz-menu-item (click)="setTitle('INPROGRESS CUSTOMERS')" ><a routerLink="/customers/inprogressCustomers" >Inprogress</a></li>
            <!-- <li nz-menu-item (click)="setTitle('PAYMENT CUSTOMERS')" ><a routerLink="/customers/paymentCustomers" >Payment</a></li> -->
            <li nz-menu-item (click)="setTitle('UPCOMING CUSTOMERS')" ><a routerLink="/customers/upcomingCustomers" >Upcoming</a></li>
            <li nz-menu-item (click)="setTitle('PARTLOAD CUSTOMERS')" ><a routerLink="/customers/partloadCustomers" >Partload</a></li>
            <li nz-menu-item (click)="setTitle('ONHOLD CUSTOMERS')" ><a routerLink="/customers/onholdCustomers" >On Hold</a></li>
            <li nz-menu-item (click)="setTitle('STORAGE CUSTOMERS')" ><a routerLink="/customers/storageCustomers" >Storage</a></li>
            <li nz-menu-item (click)="setTitle('COMPLETED CUSTOMERS')" ><a routerLink="/customers/completedCustomers" >Completed</a></li>
            <li nz-menu-item (click)="setTitle('CANCELLED CUSTOMERS')" ><a routerLink="/customers/cancelledCustomers" >Cancelled</a></li>
          </ul>
        </li>

        <li nz-submenu nzTitle="Expenses" nzIcon="transaction" *ngIf="isAdmin()">
            <!-- <i nz-icon nzType="fund" nzTheme="outline"></i> -->
          <ul>
            <li nz-menu-item (click)="setTitle('MATERIALS EXPENSES')" ><a routerLink="/expenses/packingMaterials" >Materials</a></li>
          </ul>
          <ul>
            <li nz-menu-item (click)="setTitle('OTHER EXPENSES')" ><a routerLink="/expenses/otherExpense" >Others</a></li>
          </ul>
        </li>

        <li nz-submenu nzTitle="Salary" nzIcon="money-collect" *ngIf="isAdmin()">
            <!-- <i nz-icon nzType="fund" nzTheme="outline"></i> -->
          <ul>
            <li nz-menu-item (click)="setTitle('LOAD SALARY DETAILS')" ><a routerLink="/salary/loadDetails" >Load Details</a></li>
          </ul>
          <ul>
            <li nz-menu-item (click)="setTitle('CALCULATE SALARY')" ><a routerLink="/salary/calculateDetails" >Calculate</a></li>
          </ul>
        </li>

        <li nz-submenu nzTitle="Lists" nzIcon="unordered-list" *ngIf="isAdmin()">
            <!-- <i nz-icon nzType="fund" nzTheme="outline"></i> -->
          <ul>
            <li nz-menu-item (click)="setTitle('VENDORS')" ><a routerLink="/lists/vendor" >Vendors</a></li>
          </ul>
          <ul>
            <li nz-menu-item (click)="setTitle('MISCELLANEOUS')" ><a routerLink="/lists/miscellaneous" >Miscellaneous</a></li>
          </ul>
          <ul>
            <li nz-menu-item (click)="setTitle('TRUCK')" ><a routerLink="/lists/truck" >Truck</a></li>
          </ul>
          <!-- <ul>
            <li nz-menu-item (click)="setTitle('EMPLOYEES')" ><a routerLink="/lists/employees" >Employees</a></li>
          </ul> -->
        </li>
          
        <li nz-menu-item (click)="showLogoutConfirmModal()" >
          <span nz-icon nzType="logout" nzTheme="outline"></span>
          <span>Logout</span>
        </li>

    </ul>
  </nz-sider>
  <nz-layout >
    <nz-header>
      <div class="header">
        <span class="title" >{{helperService.getTitle()}}</span>
        <span class="user" >{{currentUser.name}}</span>
        <button class="review-btn" (click)="copyReviewMsg()" nz-button nzType="primary" nzSize="large" nzShape="square"><i nz-icon nzType="message"></i></button>
        
      </div>
      <!-- <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'" (click)="isCollapsed = !isCollapsed"></i> -->
    </nz-header>
      <nz-content class="contentLayout">
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
  </nz-layout>
</nz-layout>

<nz-layout class="layout" *ngIf="!isLoggedIn">
  <router-outlet></router-outlet>
</nz-layout>


<div id="loader" *ngIf = "isLoading" ></div>

