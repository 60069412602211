export const Constants = {
    // truckList: [ 
    //     {label: 'No Truck', value: 'No Truck'},
    //     {label: 'Two Wheeler', value: 'Two Wheeler'},
    //     {label: 'Tata Ace Open', value: 'Tata Ace Open'},
    //     {label: 'Tata Ace Closed', value: 'Tata Ace Closed'},
    //     {label: 'Tata Ace 2 Trip', value: 'Tata Ace 2 Trip'},
    //     {label: 'Dost Open', value: 'Dost Open'},
    //     {label: 'Dost 2 Trip', value: 'Dost 2 Trip'},
    //     {label: 'Dost Closed', value: 'Dost Closed'},
    //     {label: 'New Dost', value: 'New Dost'},
    //     {label: 'New Bolero', value: 'New Bolero'},
    //     {label: 'Tata 407 Open', value: 'Tata 407 Open'},
    //     {label: 'Tata 407 Closed', value: 'Tata 407 Closed'},
    //     {label: 'Tata 407 2 Trip', value: 'Tata 407 2 Trip'},
    //     {label: 'Eicher 14 Feet Open', value: 'Eicher 14 Feet Open'},
    //     {label: 'Eicher 14 Feet Closed', value: 'Eicher 14 Feet Closed'},
    //     {label: 'Eicher 17 Feet Open', value: 'Eicher 17 Feet Open'},
    //     {label: 'Eicher 17 Feet Closed', value: 'Eicher 17 Feet Closed'},
    //     {label: 'Eicher 19 feet Open', value: 'Eicher 19 feet Open'},
    //     {label: 'Eicher 19 feet Closed', value: 'Eicher 19 feet Closed'},
    //     {label: 'Eicher 20 Feet Open', value: 'Eicher 20 Feet Open'},
    //     {label: 'Eicher 2 Trip', value: 'Eicher 2 Trip'}
    //   ],
    truckList: [ 
      {label: 'Manpower', value: 'Manpower'},
      {label: 'No Truck', value: 'No Truck'},
      {label: 'Two wheeler', value: 'Two wheeler'},
      {label: 'Small Open', value: 'Small Open'},
      {label: 'Two Small Open', value: 'Two Small Open'},
      {label: 'Dost or Bolero 8 feet length Open', value: 'Dost or Bolero 8 feet length Open'},
      {label: 'Dost or Bolero 8 feet length Closed', value: 'Dost or Bolero 8 feet length Closed'},
      {label: 'Medium Size 10 feet length Open', value: 'Medium Size 10 feet length Open'},
      {label: 'Medium Size 10 feet length 2 trip', value: 'Medium Size 10 feet length 2 trip'},
      {label: 'Medium Size Closed', value: 'Medium Size Closed'},
      {label: 'One medium size & Small size Open', value: 'One medium size & Small size Open'},
      {label: 'Eicher 14 Feet Open ', value: 'Eicher 14 Feet Open '},
      {label: 'Eicher 14 Feet Closed', value: 'Eicher 14 Feet Closed'},
      {label: 'Eicher 14 Feet 2 Trip Open', value: 'Eicher 14 Feet 2 Trip Open'},
      {label: 'Eicher 14 Feet 2 Trip Closed', value: 'Eicher 14 Feet 2 Trip Closed'},
      {label: 'Eicher 17 Feet Open', value: 'Eicher 17 Feet Open'},
      {label: 'Eicher 17 Feet Closed', value: 'Eicher 17 Feet Closed'},
      {label: 'Eicher 17 Feet 2 Trip Open', value: 'Eicher 17 Feet 2 Trip Open'},
      {label: 'Eicher 17 Feet 2 Trip Closed', value: 'Eicher 17 Feet 2 Trip Closed'},
      {label: 'Eicher 20 Feet Open', value: 'Eicher 20 Feet Open'},
      {label: 'Eicher 20 Feet Closed', value: 'Eicher 20 Feet Closed'}  
    ],
    statusList: [ 
        {label: 'Pending', value: 'pending'},
        {label: 'Quotation Done', value: 'quotation'},
        {label: 'Confirmed', value: 'confirmed'},
        {label: 'On Hold', value: 'hold'},
        {label: 'In Progress', value: 'inprogress'},
        // {label: 'Payment', value: 'payment'},
        {label: 'Storage', value: 'storage'},
        {label: 'Part Load', value: 'partload'},
        {label: 'Completed', value: 'completed'},
        {label: 'Cancelled', value: 'cancelled'},
      ],
    LoadTypeList: [ 
        {label: 'Local', value: 'local'},
        {label: 'Long', value: 'long'},
        {label: 'Sharing', value: 'sharing'},
        {label: 'Storage', value: 'storage'}
      ],
    stepsList: [ 
        {label: 'Normal', value: 'normal'},
        {label: 'Wide', value: 'wide'},
        {label: 'Narrow', value: 'narrow'}
      ],
    liftList: [ 
        {label: 'Service', value: 'service'},
        {label: 'Passenger', value: 'passenger'},
        {label: 'Passenger - No Major Items', value: 'passengerNMI'},
        {label: 'No Lift', value: 'nolift'}
      ],
    trackStatusList: [
        { label: 'Goods Packed', value: 'goodsPacked', timeLabel: 'goodsPackedTime', dateLabel: 'goodsPackedDate' },
        { label: 'Source Hub', value: 'sourceHub', timeLabel: 'sourceHubTime', dateLabel: 'sourceHubDate' },
        { label: 'In transit', value: 'inTransit', timeLabel: 'inTransitTime', dateLabel: 'inTransitDate' },
        { label: 'Destinaion Hub', value: 'destinationHub', timeLabel: 'destinationHubTime', dateLabel: 'destinationHubDate' },
        { label: 'Delivered', value: 'delivered', timeLabel: 'deliveredTime', dateLabel: 'deliveredDate' }
    ],
    reasonList: [
      {label: 'High Cost', value: 'High Cost'},
      {label: 'Change in Plan', value: 'Change in Plan'},
      {label: 'Booked other packers', value: 'Booked other packers'},
      {label: 'Not interested', value: 'Not interested'},
      {label: 'No response', value: 'No response'}
    ],
    foodList: [
      {label: 'Not Informed', value: 'notInformed'},
      {label: 'Informed', value: 'informed'}
    ],
    roleList: [
      {label: 'Admin', value: 'admin'},
      {label: 'Employee', value: 'employee'},
      {label: 'Worker', value: 'worker'}
    ],
    key: 'T@mizh22G33thu'
}