import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from "./login/login.component";
import { LoginGuard } from "./services/loginguard.service";
import { AuthGuard } from './services/authguard.service';

const routes: Routes = [
  {
    path: 'customers',
    loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'followup',
    loadChildren: () => import('./modules/customer-followup/customer-followup.module').then(m => m.CustomerFollowupModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'calendar',
    loadChildren: () => import('./modules/calendar/calendar.module').then(m => m.CalendarModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'expenses',
    loadChildren: () => import('./modules/expenses/expenses.module').then(m => m.ExpensesModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'lists',
    loadChildren: () => import('./modules/lists/lists.module').then(m => m.ListsModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'salary',
    loadChildren: () => import('./modules/salary/salary.module').then(m => m.SalaryModule),
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: '',
    component: LoginComponent,
    canActivate: [
      LoginGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
