import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { UserService } from './user.service';
import { CheckUserService } from './checkUser.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
  
  constructor(private router: Router, 
    private loginService: LoginService, 
    private userService: UserService) {
    
  }

  canActivate() {
    if (localStorage.getItem('spmLoggedIn') === 'true') {
      return true;
    }
    this.loginService.logout();
    return false;
  }
}