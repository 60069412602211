import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CopyService } from './copy.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})

export class ContentService {

    constructor(
        private messageService: NzMessageService,
        private copySvc: CopyService
    ) {}

    thankNote: string = "Thanks! \nOperations Team \nStudent Packers and Movers";

    joinTruck(str: any) {
        if(str) {
            let arr = str.split(',');
            let finalRes = arr.join(' Truck and ');
            return finalRes;
        } else {
            return '';
        }
        
    }

    getCommonInfo(customer: any, quotation: any) {
        let truckType = customer.truckType;
        let tarpaulin = truckType.toLowerCase().includes('open') ? 'with waterproof tarpaulin' : '';
        console.log(tarpaulin);
        let commonInfo1 = "*Student Packers and Movers*\n\n" + 
                    "Dear Customer, \n\n" +
                    "We thank you for your enquiry with us to shift your valuable goods.\n\n" + 
                    "Quote ID: *" + this.getValidVal(customer.customerId) + "*\n\n" + 
                    "Shifting From -  *" + this.getValidVal(customer.fromLocation) + "*  \n" +
                    "Shifting To - *" + this.getValidVal(customer.toLocation) + "*\n" +
                    "Shifting Date  -  " + this.getFormatedDTForm(customer.shiftingDate, 'date') + "\n" +
                    "Shifting Day    -  " + this.getFormatedDTForm(customer.shiftingDate, 'day') + " \n" +
                    "Shifting Time  -   " + this.getFormatedDTForm(customer.shiftingTime, 'time') + " \n\n";
        if(customer.loadType == 'sharing') {
            commonInfo1 = commonInfo1 + "*Delivery of goods - Refer to no.18 in T&C.*\n\n";
        }
        let commonInfo2 = "*SERVICES PROVIDED*:- \n\n" + 
                    "1. Packing\n" +
                    "2. Loading - *" + this.getFloorVal(customer.fromFloor) + "*\n" +
                    "3. Transportation\n" +
                    "4. Unloading - *" + this.getFloorVal(customer.toFloor) + "* \n\n" +
                    "*SHIFTING CHARGES -  Rs." + quotation[quotation.selectedQuote] + "*\n" +
                    "(You can provide *Food for the workers* under no compulsion.)\n\n" +
                    "*TRUCK TYPE* - " + this.joinTruck(customer.truckType) + " Truck " + tarpaulin + "\n\n";
        let commonInfo = commonInfo1 + commonInfo2;
        return commonInfo;
    }

    getQuoteAmount(quotation: any) {

        if(quotation && quotation.selectedQuote) {
            let str = quotation.selectedQuote;
            return quotation[str];
        } else {
            return '0';
        }

    }

    getValidVal(value: any) {
        return value ? value.trim() : '';
    }

    getFloorVal(value: any) {
        return value ? value + ' Floor' : '';
    }

    checkIsValPresent(value: any) {
        let data = value ? value.toString() : value;
        return data && data.trim() ? true : false;
    }

    getFormatedDTForm(value: any , format: string) {
        if(value && value != "0000-00-00") {
            if (format === 'date') {
                return moment(value).format('DD/MM/YYYY');
            } else if (format === 'day') {
                return moment(value).format('dddd');
            } else if (format === 'time') {
                return moment(value).format('h A')
            }

        } else {
            return '';
        }
    }

    getThingsToRem() {
        let data = "*Things to remember:* \n\n" +
        "1. Incase of a Front Load Washing Machine kindly provide us the screws to lock the drum.\n\n" +
        "2. Our team won't do house cleaning work at your source and destination location.\n\n" +
        "3. Our team won't do deep arrangements of your house holds in your destination location. Only major items will be kept in your preferred place and  boxes and gunny bags will be kept in the respective rooms as you instruct.\n\n" ;
        return data;
    }

    getCommonTnC() {
        let common =  "*TERMS AND CONDITIONS*\n\n" +
                    "1. Kindly *carry all your valuable belongings* (Documents, Jewellery, Tablets, Keys, Cash, Camera, etc.,) before we start our work at your place.\n\n" +
                    "2. It is mandatory to *switch off your refrigerator* a day prior to the shifting process or at least half day prior to the shifting process. In case any gas leakage problem occurs, we won't take responsibility.\n\n" +
                    "3. *Dismantle work* is done only for the following items : Cot(Nut and Bolt type), Dining table, TV wall mount, TV dish, Fan, Water heater, Water purifier Inverter.\n" +
                    "*Note* : We won't dismantle any sort of interior works like chimney, kitchen interiors, wardrobes, lights, etc,.\n\n" +
                    "4. *Installation work* is done only for Cot - Nut and Bolt type and Dining table. We *won't handle electrical and plumbing work* at your new house.\n\n" +
                    "5. We take *whole responsibility for the external damages* that occur during the course of shifting.\n\n" +
                    "6. We are *not responsible for the internal damages* i.e. technical failure of your electronic goods like fridge, washing machine, TV, etc., that occur during the course of shifting.\n\n" +
                    "7. Shifting *cost is calculated* according to the distance between the locations, floor of residence in both the location, walkable distance between the truck and the loading/unloading point and items. If there is any changes in the information provided earlier, we will revise the shifting cost accordingly.\n\n" +
                    "8. *GST 18% will be additional* if bill is required.\n\n" +
                    "9. *Insurance amount will be additional* if required.\n\n" +
                    "10. *Packing of loose items* will be done using carton boxes and gunny bags.\n\n";
        return common;
    }

    getlocalTnC() {
        let local = "11. Packing of major items is *done using foam sheets* during local movement.\n\n" +
                    "12. The *AC charges mentioned are only for dismantle and installation of the AC*. It doesn’t include any of the services like gas refilling, services, copper wire extension, AC stand bolts, foam sheet, cleaning, etc,.\n\n" +
                    "13. Ac installation work can be done on the same day or after 1-2 days depending on the technician availability.\n\n" +
                    "14. Packing materials like carton boxes and gunny bags are *returnable*. It can be *surrendered* on the day of the shifting process or we will collect it from your place at your suitable time after 2-3 days of unpacking. *Charges may be applicable* for the collection of packing materials. *Please refer to No. 15*.\n" +
                    "*Note* : We need *two day's notice* to collect the packing materials from your place.\n\n" +
                    "15. If you are planning to keep our packing materials with you, the *pricing* is as below:\n" +
                    " a. Box - Rs.80 to Rs.100 (based on the quality of the box) \n b. Bag - Rs. 40 \n c. Plastic Box - Rs. 250/box\n\n" +
                    "16. Packing materials *collection charges* vary as below - \n" +
                    " a.  Upto 00-10km radius - Rs.200 \n b. Upto 10-20km radius - Rs.300 \n c. Upto 20-30km radius - Rs.500 \n d. Upto 30-40km radius - Rs.800 \n" +
                    "*Note* : Distance is calculated from our office in *MEDAVAKKAM*";
        return local;
    }

    getLongTnC() {
        let long =  "11. Packing of major items is *mandatory* during long movements.\n\n" +
                    "12. Packing materials like carton boxes, gunny bags and plastic trays are *not returnable*.\n\n" + 
                    "13. If *two wheeler is transported* along with your household goods, it is *mandatory* to provide us with a *copy of RC and Insurance* of the two wheeler.\n\n" +
                    "14. Incase of two wheeler transportation, there should be *no fuel in the vehicle*. If there is any fuel, it will be drained out on the time of loading.\n\n" +
                    "15. We are *not responsible for the labor union problem* that arises in the destination point. It should be sorted out by the customer.\n\n" +
                    "16. *Inflammables are restricted* for long movement such as *filled gas cylinders(empty cylinders are accepted)*, petrol, diesel, kerosene, chemicals, explosives, etc,.\n\n" +
                    "17. We are *not responsible for the delay in delivery due to natural calamity, strikes, accidents, etc,*.\n\n" +
                    "18. In case of a *vehicle breakdown*, it will take a maximum of half day delivery delay or the goods will be transferred to another truck and will be delivered.\n\n";
        return long;
    }

    getPartLoadTnC() {
        let partLoad = this.getLongTnC() +
                        "19. *Delivery of the items will be within 7 business days*. Sundays, Festival days will be considered holiday.\n\n";
        return partLoad;
    }

    getStorageTnc() {
        let storage = "11. Packing materials like carton boxes, gunny bags and plastic trays are *not returnable.*\n\n" +
                      "12. *Complete packing is mandatory* for all the items to be stored in the warehouse.\n\n" +
                      "13. All the containers containing *liquid will be emptied* i.e. oil, water, etc,.\n\n" +
                      "14. *Two wheeler or four wheeler storage costs are not included* in the quote provided. Separate charges may be applicable depending on the type of the vehicle.\n\n" +
                      "15. It is *mandatory* to provide us with a *copy of RC and Insurance* of the two wheeler that we store in our warehouse.\n\n" +
                      "16. It is *mandatory to empty fuel in the vehicle* before storing it in the warehouse. If there is fuel in the vehicle, it will be emptied before it is stored in the warehouse.\n\n" +
                      "17. *Inflammables are restricted* for storage such as *filled gas cylinders(empty cylinders are accepted)*, petrol, diesel, kerosene, chemicals, explosives, etc,.\n\n" +
                      "18. We don't take *Fish tank ,pets , flower pots*.\n\n" +
                      "19. *Charges for the delivery of the goods* will be separate *based on the delivery location* when the storage space is vacated from our warehouse.\n\n" +
                      "20. *One week's notice* is mandatory for delivery of the stored items.\n\n" +
                      "21. Storage *rent payment date* will be a full month cycle from the date you avail our storage service.\n\n";
        return storage;
    }

    getLocalPymtTerms() {
        let paymentTerms = "*PAYMENT TERMS*:-\n\n" +
                            "1. Confirmation Advance - Rs. 1000\n\n" +
                            "2. Remaining Payment - End of the shifting process (Cash Only)\n\n";
        return paymentTerms;
    }

    getLongPymtTerms() {
        let paymentTerms = "*PAYMENT TERMS*:-\n\n" +
                            "1. Confirmation Advance - Rs. 1000\n\n"+
                            "2. At loading point - 80% of booking amount\n\n"+
                            "3. At unloading point - 20% of booking amount\n\n";
        return paymentTerms;
    }

    getOtherCharges(customer: any, quotation: any) {
        if(customer.loadType == "local") {
            let otherInfo = "*OTHER CHARGES*:- \n\n" +
                            "1. Packing material charges are additional. Kindly refer T&C in quotation*.\n\n";
            let count = 1;
            if(this.checkIsValPresent(quotation.AC)) {
                count = count + 1;
                otherInfo = otherInfo + count.toString() +". AC charges - Rs."+ quotation.AC +" per AC to dismantle at source location and installation at destination location. Refer to T&C in quotation for further details on AC charges\n\n";
            }
            if(this.checkIsValPresent(quotation.carpenter)) {
                count = count + 1;
                otherInfo = otherInfo + count.toString() + ". Carpenter charges - Rs."+ quotation.carpenter + "\n\n";
            }
            if(this.checkIsValPresent(quotation.additionalTruck)) {
                count = count + 1;
                otherInfo = otherInfo + count.toString() + ". Additional small truck - Rs."+ quotation.additionalTruck + " (if required)\n\n";
            }
            return otherInfo;
        } else if(customer.loadType === 'long' || customer.loadType == 'sharing') {
            let otherInfo = "";
            let count = 0;
            if(this.checkIsValPresent(quotation.AC)) {
                count = count + 1;
                otherInfo = otherInfo + count.toString() +  ". AC charges - Rs."+ quotation.AC +" per AC to dismantle at source location.\n\n";
            }
            if(this.checkIsValPresent(quotation.carpenter)) {
                count = count + 1;
                otherInfo = otherInfo + count.toString() +  ". Carpenter charges - Rs."+ quotation.carpenter + "\n\n";
            }
            if(this.checkIsValPresent(quotation.additionalTruck)) {
                count = count + 1;
                otherInfo = otherInfo + count.toString() +  ". Additional small truck - Rs."+ quotation.additionalTruck + " (if required)\n\n";
            }
            if(this.checkIsValPresent(quotation.AC) || this.checkIsValPresent(quotation.carpenter) || this.checkIsValPresent(quotation.additionalTruck)) {
                otherInfo = "*OTHER CHARGES*:- \n\n" + otherInfo;
            }
            return otherInfo;
        } else {
            return '';
        }
    }

    public getLocalQuotation(customer: any, quotation: any) {
        let otherInfo = this.getOtherCharges(customer, quotation);
        let localQuote = this.getCommonInfo(customer, quotation) + otherInfo + this.getThingsToRem() + this.getLocalPymtTerms() + this.getCommonTnC() + this.getlocalTnC();
        this.copySvc.copy(localQuote);
    }

    public getLongQuotation(customer: any, quotation: any) {
        let otherInfo = this.getOtherCharges(customer, quotation);
        let localQuote = this.getCommonInfo(customer, quotation) + otherInfo + this.getThingsToRem() + this.getLongPymtTerms() + this.getCommonTnC() + this.getLongTnC();
        this.copySvc.copy(localQuote);
    }

    public getPartLoadQuotation(customer: any, quotation: any) {
        let otherInfo = this.getOtherCharges(customer, quotation);
        let partLoadQuote = this.getCommonInfo(customer, quotation) + otherInfo + this.getThingsToRem() + this.getLongPymtTerms() + this.getCommonTnC() + this.getPartLoadTnC();
        this.copySvc.copy(partLoadQuote);
    }

    public getStorageQuotation(customer: any, quotation: any) {
        let commonInfo = "*Student Packers and Movers*\n\n" + 
                    "Dear Customer, \n\n" +
                    "We thank you for your enquiry with us to shift your valuable goods.\n\n" + 
                    "Quote ID: *SPM123456*\n\n" + 
                    "Shifting From -  *Thiruporur*  \n" +
                    "Shifting To - *WAREHOUSE(Medavakkam / Thiruporur)*\n" +
                    "Shifting Date  -  05/06/2022\n" +
                    "Shifting Day    -  SUNDAY \n" +
                    "Shifting Time  - 3PM \n\n" +
                    "*ONE TIME PICKUP CHARGES -  Rs.26500*\n\n" +
                    "*OTHER CHARGES:-*\n" +
                    "1. *Monthly Storage Rent* - Rs. 3000\n" +
                    "2. AC Charges - Rs.750 for dismantle at the source location.\n\n"+
                    "*SERVICES PROVIDED*:- \n" + 
                    "1. Packing\n" +
                    "2. Loading - *1st Floor*\n" +
                    "3. Transportation - Eicher 17 feet Open Truck with waterproof tarpaulin\n" +
                    "4. Unloading - *2nd Floor* \n\n";
        let storage = commonInfo + this.getThingsToRem() + this.getCommonTnC() + this.getStorageTnc();
        this.copySvc.copy(storage);
    }

    getJobAck(customer, quotation) {
        let truckType = customer.truckType;
        let tarpaulin = truckType.toLowerCase().includes('open') ? 'with waterproof tarpaulin' : '';
        let otherCharges = this.getOtherCharges(customer, quotation);
        let data = "*Job Acknowledgment*\n\n" +
                    "Dear Customer, \n\n" +
                    "Shipment ID: *" + this.getValidVal(customer.customerId) + "*\n\n" +
                    "You are requested to pay a *confirmation amount as advance of Rs.1000* to any of the "+
                    "below mentioned payment credentials for us to book your slot on *DATE:  " + 
                    this.getFormatedDTForm(customer.shiftingDate, 'date') + ", " + 
                    this.getFormatedDTForm(customer.shiftingDate, 'day') + " at " + 
                    this.getFormatedDTForm(customer.shiftingTime, 'time') + "*.\n\n" +
                    "*----------------------------------------*\n\n" +
                    "*SHIFTING CHARGES -  Rs." + quotation[quotation.selectedQuote] + "*\n\n" +
                    "*TRUCK TYPE* - " + this.joinTruck(customer.truckType) + " Truck " + tarpaulin + "\n\n" +
                    otherCharges +
                    "*----------------------------------------*\n\n"+
                    "*PAYMENT METHODS*:-\n\n" +
                    "Kindly *mention your Shipment ID and share the screenshot* of the same so that we can book "+
                    "the slot allotted for you. If already done please ignore this statement. \n\n" +
                    "1. *Gpay / Phonepe / Paytm - 7092343916*\n\n" +
                    "2. *UPI ID:* studentpackersmovers@okicici\n\n" +
                    "3. *Bank Account Details*:-\n" +
                    "Name : Student Packers and Movers\n" +
                    "Bank Name : City Union Bank\n" +
                    "Branch : Jalladianpet\n" +
                    "IFSC code : CIUB0000307\n" +
                    "A/C number : 510909010135504\n" +
                    "Account Type : CURRENT\n\n" +
                    "Kindly acknowledge for further process of confirmation.\n\n" +
                    "Thanks! \nStudent Packers & Movers."
        this.copySvc.copy(data);

    }

    getPaymntRecpt(customer, quotation) {
        let pymtRcpt = "";
        let totalAmount = quotation[quotation.selectedQuote];
        let remAmount = Number(totalAmount) - 1000;
        let data = "*Payment Acknowledgement Receipt*\n\n" +
                    "Dear Customer, \n\n" +
                    "Following are the details about your payment towards us.\n\n" +
                    "Shipment ID: *" + this.getValidVal(customer.customerId) + "*\n\n" +
                    "Total Discussed payment -  Rs."+ totalAmount + "\n\n" + 
                    "Confirmation Advance Amount - 1000 ( Received via Gpay )\n\n" +
                    "*Remaining balance - Rs."+ remAmount + "*\n\n";
        let longData = "80% at loading point ("+ customer.fromLocation +") - Rs." + this.getValidVal(quotation.loading) + " \n"+
                        "20% at unloading point ("+ customer.toLocation +") - Rs." + this.getValidVal(quotation.unloading) + " \n\n";
                
        if(customer.loadType !== 'local') {
            pymtRcpt = data + longData + this.thankNote;
        } else {
            pymtRcpt = data + this.thankNote;
        }
        this.copySvc.copy(pymtRcpt);
    }

    getRevisedQuote(customer, quotation) {
        let truckType = customer.truckType;
        let tarpaulin = truckType.toLowerCase().includes('open') ? 'with waterproof tarpaulin' : '';
        let otherCharges = this.getOtherCharges(customer, quotation);
        let data = "*Revised Quote*\n\n" +
                    "Dear customer, \n\n" +
                    "Shipment ID: *" + this.getValidVal(customer.customerId) + "*\n\n" +
                    "As per the final discussion, we have revised your quote.\n\n" +
                    "*SHIFTING CHARGES -  Rs."+quotation[quotation.selectedQuote]+"*\n\n" +
                    "*TRUCK TYPE* - " + this.joinTruck(customer.truckType) + " Truck " + tarpaulin + "\n\n" +
                    otherCharges +
                    "Thanks!\n" +
                    "Student Packers & Movers";
        this.copySvc.copy(data);
    }

    getReviewMsg() {
        let msg = "Dear customer, hope you had a great shifting experience with Students Packers and Movers. Kindly spare us a minute by clicking the below link to share your valuable feedback on our service by helping us improve.\n\n" +
                    "https://g.page/studentpackersmovers/review";
        this.copySvc.copy(msg);
    }

    getPrerequisites() {
        let data = "*Student Packers and Movers*\n\n"+
                    "Dear Customer, \n\n" +
                    "We have received your payment. "+
                    "We thank you for choosing us to give you our best service in moving your households. "+
                    "Following are the prerequisites and the details required for us to provide you a hassle-free shifting service.\n\n" +
                    "*Prerequisites for moving*\n\n" +
                    "1. Kindly complete all the move in and move out formalities if there is any in pickup and drop location to reduce the time wastage.\n\n" +
                    "2. Kindly check the time allowed for the truck to come in and go out in pickup and drop location and inform us the same to avoid the delay in starting the process.\n\n" +
                    "*Provide us the following*\n\n" +
                    "1. Pickup address with map location\n\n" +
                    "2. Delivery address with map location\n\n" +
                    "3. Alternate contact number(2 nos.)\n\n";
        this.copySvc.copy(data);
    }

    getReschedule(customer) {
        let data = "Dear Customer,\n\n" +
                    "Shipment ID: *" + this.getValidVal(customer.customerId) + "*\n\n" +
                    "As per your request we have *Re-scheduled* your shifting to *"+this.getFormatedDTForm(customer.shiftingDate, 'date') +
                    ", "+this.getFormatedDTForm(customer.shiftingDate, 'day') +
                    "* at *" + this.getFormatedDTForm(customer.shiftingTime, 'time') + "*.\n\n" +
                    "Kindly confirm if we can make it on the mentioned date and time.\n\n" +
                    "Thanks!\n" +
                    "Student Packers and Movers";
        this.copySvc.copy(data);
    }

    getOnHold(customer) {
        let data = "Dear Customer,\n\n" +
                    "Shipment ID: *" + this.getValidVal(customer.customerId) + "*\n\n" +
                    "As per your request we have *Held* your shifting process which was planned on *"+this.getFormatedDTForm(customer.shiftingDate, 'date') +
                    ", "+this.getFormatedDTForm(customer.shiftingDate, 'day') +
                    "* at *" + this.getFormatedDTForm(customer.shiftingTime, 'time') + "*. We can make it on your preferred date and time. \n\n" +
                    "Kindly let us know one week prior to your planned date.\n\n" +
                    "Thanks! \n" +
                    "Student Packers and Movers";
        this.copySvc.copy(data);
    }

    getJobCancel(customer, quotation) {
        let data = "*Job cancellation acknowledgement*\n\n" +
                    "Dear customer,\n\n" +
                    "Shipment ID: *" + this.getValidVal(customer.customerId) + "*\n\n" +
                    "This is to inform that your order has been *cancelled* as per your request. Kindly *initiate the cancellation fee* as per our norms as the cancellation is requested from your end. \n\n" +
                    "*Total Discussed Payment - " + quotation[quotation.selectedQuote] + "*\n\n" +
                    " of discussed payment - \n\n" +
                    "Token Advance received - 1000\n\n" +
                    "*Remaining to be paid - *\n\n" +
                    "Thanks & Regards,\n" +
                    "Student Packers & Movers";
        this.copySvc.copy(data);
    }

    getBCForBoys(details) {
        let data = "*" + details.customerId + "*\n" +
                    "Customer Name: " + details.customerName + "\n" +
                    "Phone : " + details.phoneNumber + "\n" +
                    "Location: " + details.toLocation + "\n\n" +
                    "*Address:*\n" +
                    this.getValidVal(details.address) + " \n\n" +
                    "*Material Count:*\n" +
                    "Box : " + this.getValidVal(details.box) + "\n" +
                    "Bag : " + this.getValidVal(details.bag) + "\n" +
                    "Foam Sheet : " + this.getValidVal(details.foamSheet) + "\n\n" +
                    "*Amount:* " + this.getValidVal(details.collectionCharges) + "\n" +
                    "Handled By: " + details.createdBy;
        this.copySvc.copy(data);
    }
    
    getBCForCustomer(details) {
        let data = "Dear customer, \nHope the shifting process went smoothly. " +
                    "Kindly call us if you have any concerns or complaints regarding our work. " +
                    "Below are details of the packing materials left at your place:\n\n" +
                    "*" + details.customerId + "*\n" +
                    "Box : " + this.getValidVal(details.box) + "\n" +
                    "Bag : " + this.getValidVal(details.bag) + "\n" +
                    "Foam Sheet : " + this.getValidVal(details.foamSheet) + "\n\n" +
                    "*Amount:* " + this.getValidVal(details.collectionCharges) + "\n" +
                    "Kindly call us once the unpacking is done.";
        this.copySvc.copy(data);
    }


}