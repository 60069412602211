import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})

export class CopyService {

    constructor(
        private messageService: NzMessageService
    ) {}

    copy(text) {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.messageService.info("Copied!")
    }
}