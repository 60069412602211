import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})

export class LoginService {

    isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(localStorage.getItem('spmLoggedIn') === 'true' ? true : false);
    
    constructor(private router: Router) {

    }

    login() {
        localStorage.setItem('spmLoggedIn', 'true');
        this.router.navigate(['/customers/pendingCustomers']);
        this.isLoggedIn.next(true);
    }

    logout() {
        localStorage.setItem('spmLoggedIn', 'false');
        localStorage.removeItem('spmUser');
        this.router.navigate(['']);
        this.isLoggedIn.next(false);
    }
}