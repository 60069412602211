import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';



@Injectable({
    providedIn: 'root',
})
export class HttpService {
    apiURL = 'http://spm-server.studentpacmov.com';
    //apiURL = 'http://34.131.102.139:3000'; //instance-spm1
    // apiURL = 'http://35.240.149.133:3000'; //instance-spm
    // apiURL = 'http://localhost:3000';
    constructor(private httpClient: HttpClient) {

    }

    /** http get method
         * @param url - url for http request
         * @param params - it construct url with params
         */
    get(url: string, params?: any) {
        return this.httpClient
            .get(this.apiURL.concat(url), { params: params })
            .pipe(catchError(this.handleError));
    }

    /** http post method
       * @param url - url for http request
       * @param params - it construct url with params
       */
    post(url: string, params?: any) {
        return this.httpClient
            .post<any>(this.apiURL.concat(url), params)
            .pipe(catchError(this.handleError));
    }

    /** http put method
       * @param url - url for http request
       * @param params - it construct url with params
       */
    put(url: string, params?: any) {
        return this.httpClient
            .put<any>(this.apiURL.concat(url), params)
            .pipe(catchError(this.handleError));
    }

    /** http delete method
         * @param url - url for http request
         * @param params - it construct url with params
         */
    delete(url: string, params?: any) {
        return this.httpClient
            .delete<any>(this.apiURL.concat(url), { params: params })
            .pipe(catchError(this.handleError));
    }
    
    
    /** send message method
         * @param url - url for sending message
         */
    sendMessage(url: string) {
        return this.httpClient
            .get(url)
            .pipe(catchError(this.handleError));
    }


    private handleError(error: HttpErrorResponse) {
        let errMsg = '';
        // Client Side Error
        if (error.error instanceof ErrorEvent) {
            errMsg = `Error: ${error.error.message}`;
        } else {  // Server Side Error
            errMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        // return an observable
        return throwError(errMsg);
    }
}
