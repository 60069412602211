import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard  {
  
  constructor(private router: Router) {
    
  }

  canActivate() {
    if (localStorage.getItem('spmLoggedIn') === 'true') {
        this.router.navigate(['/customers/pendingCustomers']);
      return false;
    }
    return true;
  }
}