import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

//app imports
import { LoginService } from "./services/login.service";
import { UserService } from './services/user.service';
import { HelperService } from './services/helper.service';
import { ContentService } from './services/content.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isLoggedIn:boolean;
  isLoading: boolean;
  currentUser: any;
  isCollapsed:boolean = false;

  constructor(
    private loginService: LoginService,
    private modalService: NzModalService,
    private userService: UserService,
    public helperService: HelperService,
    private router: Router,
    private contentSvc: ContentService
    ) { }

    ngOnInit () {
      this.router.navigate(['/customers/pendingCustomers']);
      this.isCollapsed = false;
      this.loginService.isLoggedIn.subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
      this.userService.currentUser.subscribe((user) => {
        this.currentUser = user;
      })
      this.helperService.showLoader.subscribe((isLoading) => {
        this.isLoading = isLoading;
      })
    }

    showLogoutConfirmModal(): void {
      this.modalService.confirm({
        nzTitle: 'Do you want to logout?',
        nzOnOk: () => this.logout()
      });
    }

    setTitle(title) {
      this.helperService.setTitle(title);
    }
  
    logout() {
      this.loginService.logout();
    }

    copyReviewMsg() {
      this.contentSvc.getReviewMsg();
    }

    isAdmin() {
      let role = this.currentUser.role;
      if(role.toLowerCase() === 'admin'){
        return true;
      } else {
        return false;
      }
    }
}
