import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})

export class HelperService {
    showLoader:  BehaviorSubject<boolean> = new BehaviorSubject(false);
    tableLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
    title = 'PENDING CUSTOMERS'

    constructor(
        private messageService: NzMessageService
    ) {}

    getTitle() {
        return this.title;
    }

    setTitle(title) {
        this.title = title;
    }

    isLoading(value:boolean) {
        this.showLoader.next(value);
    }

    setTableLoader(value: boolean) {
        this.tableLoader.next(value);
    }

    isValidPhoneNumber(phone: string): boolean {
        let phoneRegex = /^\d{10}$/;
        return phone.match(phoneRegex) ? true : false;
    }
    
    copyDetails(currentCustomer) {
        let text = "Shipment ID: " + currentCustomer.customerId + "\n" +
              "Date: *" + moment(currentCustomer.shiftingDate).format("DD/MM/YYYY") +" ( "+ moment(currentCustomer.shiftingDate).format('dddd').toUpperCase() +" )* \n" +
              "TIME: *" + moment(currentCustomer.shiftingTime).format("h A") + "*\n"+
              "----------------------------------------\n"+
              "Customer Name: " + currentCustomer.customerName + "\n" +
              "Customer Number: " + currentCustomer.phoneNumber + "\n"+
              "Alternate Number: " + this.getValidData(currentCustomer.altNumber,'existCheck')  + "\n" +
              "----------------------------------------\n"+
              "Truck Type: *" + currentCustomer.truckType + "*\n"+
              "----------------------------------------\n"+
              "From: *" + currentCustomer.fromLocation + "( Floor - " + this.getValidData(currentCustomer.fromFloor, 'nullCheck') +" / "+ this.getValidData(currentCustomer.fromLift,'lift') +" )*\n"+
              this.getValidData(currentCustomer.fromAddress, 'address') +
              "\n" +
              "To: *" + currentCustomer.toLocation + "( Floor - " + this.getValidData(currentCustomer.toFloor, 'nullCheck') +" / "+ this.getValidData(currentCustomer.toLift,'lift') +" )*\n"+
              this.getValidData(currentCustomer.toAddress, 'address') + 
              "\n" +
              "----------------------------------------\n"+
              "Food: " + this.getValidData(currentCustomer.food,'food') + "\n"+
              "Handled by: " + this.getUserName(currentCustomer.createdBy) + "\n"+
              "----------------------------------------\n"+
              "*Material*: \n\n" + this.getValidData(currentCustomer.packingMaterial,'existCheck') + "\n\n" +
              "----------------------------------------\n"+
              "*Method of Packing*: \n\n" + this.getValidData(currentCustomer.packingMethod,'existCheck') + "\n\n" +
              "----------------------------------------\n"+
              "*Team*: \n\n" + this.getValidData(currentCustomer.team, 'existCheck') + "\n\n" +
              "----------------------------------------\n"+
              "*Notes*: \n\n" + this.getValidData(currentCustomer.notes,'existCheck') + "\n\n"
        this.copy(text);
    }

    copyDetailsForAC(currentCustomer) {
        let text = "Lead \n\n" +
              "Shipment ID: " + currentCustomer.customerId + "\n" +
              "Date: " + moment(currentCustomer.shiftingDate).format("DD/MM/YYYY") +" ( "+ moment(currentCustomer.shiftingDate).format('dddd').toUpperCase() +" ) \n" +
              "TIME: " + moment(currentCustomer.shiftingTime).format("h A") + "\n"+
              "----------------------------------------\n"+
              "Customer Name: " + currentCustomer.customerName + "\n" +
              "Customer Number: " + currentCustomer.phoneNumber + "\n"+
              "Alternate Number: " + this.getValidData(currentCustomer.altNumber,'existCheck')  + "\n" +
              "----------------------------------------\n"+
              "From: " + currentCustomer.fromLocation + "( Floor - " + currentCustomer.fromFloor +" / "+ this.getValidData(currentCustomer.fromLift,'lift') +" )\n"+
              "To: " + currentCustomer.toLocation + "( Floor - " + currentCustomer.toFloor +" / "+ this.getValidData(currentCustomer.toLift,'lift') +" )\n"+
              "----------------------------------------\n"+
              "Handled by: " + this.getUserName(currentCustomer.createdBy) + "\n"+
              "----------------------------------------\n";
        this.copy(text);
    }

    
    copyDetailsForQtGrp(currentCustomer) {
        let text = "*"+currentCustomer.customerId+"*" + " - " + (currentCustomer.createdBy == 'admin' ? 'Tamizh' : currentCustomer.createdBy) + "\n\n" +
                    currentCustomer.phoneNumber + "\n\n" + 
                    "*From Location:* " + currentCustomer.fromLocation + "\n*To Location:* " + currentCustomer.toLocation + "\n\n" + 
                    "*From Floor:*  " + (currentCustomer.fromFloor ? currentCustomer.fromFloor : "-") + "  *|*  " + this.getValidData(currentCustomer.fromLift,'lift') + "\n" +
                    "*To Floor:* " + (currentCustomer.toFloor ? currentCustomer.toFloor : '-') + "  *|*  " + this.getValidData(currentCustomer.toLift,'lift') +"\n\n" +
                    "*Major Items:*  \n" + (currentCustomer.majorItems ? currentCustomer.majorItems : "-") + "\n\n";
        let truck = currentCustomer.truckType ? ("*Truck:* " + currentCustomer.truckType + "\n\n") : '';
        let notes = currentCustomer.notes ? ("*Notes:* \n" + currentCustomer.notes) : '';
         
        this.copy(text + truck + notes);
    }

    getUserName(user) {
        if(user) 
            return user == 'admin' ? 'Tamizh' : user;
        return '';
    }   

    copy(text) {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.messageService.info("Copied!")
    }

    getValidData(val, key) {
        if(this.strMatch(key,'existCheck'))
          return val ? val : ' ----- ';
        else if(this.strMatch(key,'lift'))
        {
            if(this.strMatch(val,'nolift')) 
                return val;
            else if(this.strMatch(val, 'passengerNMI'))
                return 'Passenger - No Major Items';
            else if(!val) 
                return '-';
            else 
                return val + 'Lift'
        }
        else if(this.strMatch(key,'food'))
        {
            if(this.strMatch(val,'notInformed')) 
                return 'Not Informed';
            else
                return 'Informed';
        }
        else if(this.strMatch(key,'nullCheck'))
        {
            return val ? val : '';
        }
        else if(this.strMatch(key,'address'))
        {
            return val ? val + "\n" : '';
        }

    }

    strMatch(str1: string, str2: string) {
        let a = str1 ? (str1).toLowerCase() : "";
        let b = str2 ? (str2).toLowerCase() : "";
        return (a).includes(b);
    }

    FormatNumberLength(num, length) {
        var r = "" + num;
        while (r.length < length) {
            r = "0" + r;
        }
        return r;
    }

    searchCustomerList(key, list) {
        let data = list.filter((customer) => {
                return  this.strMatch(customer.customerId , key) || 
                        this.strMatch(customer.customerName , key) ||
                        this.strMatch(customer.phoneNumber , key) ||
                        this.strMatch(customer.fromLocation , key) ||
                        this.strMatch(customer.toLocation , key) ||
                        this.strMatch(customer.shiftingDate , key) ||
                        this.strMatch(customer.createdBy , key) 
                });
        return data;
    }

    searchUserList(key, list) {
        let data = list.filter((user) => {
                return  this.strMatch(user.name , key) || 
                        this.strMatch(user.phoneNumber , key) ||
                        this.strMatch(user.stay , key)
                });
        return data;
    }

    sortCustomerListByDate(data, sortKey) {
        const sortData = data.sort((a, b) =>
            sortKey === 'ascend'
                ? moment(a.shiftingDate).isAfter(b.shiftingDate)
                    ? 1
                    : -1
                : moment(a.shiftingDate).isBefore(b.shiftingDate)
                ? 1
                : -1
            );
        return sortData;
    }

    calculateProfit(finalQuoteTotalAmount, params, currentQuotation) {
        let income = finalQuoteTotalAmount + 
                    Number(params.carpenterMargin) + 
                    Number(params.ACMargin) + 
                    Number(params.otherMargin);
                    // Number(params.ownTrans);
        let expense = Number(params.food) +
                    Number(params.labour) +
                    Number(params.petrol)+
                    Number(params.otherTrans)+
                    Number(params.outTrans) + 
                    Number(params.police)  +
                    Number(params.toll) + 
                    Number(params.diesel) + 
                    Number(params.damage) + 
                    Number(params.others) + 
                    Number(currentQuotation.GST) + 
                    Number(currentQuotation.insurance)
        let profit = income - expense;
        return profit;
    }

    splitMultilineString(str) {
        return str ? str.split(/\r?\n/).filter( x => x!= "") : [];
    }

    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    getUniqueValuesFromArray(arr) {
        let result = arr.filter(this.onlyUnique);
        return result;
    }

    getValUsingKeyFromObj(obj, key) {
        return obj[key];
    }

    groupBy(data) {
        let hash = data.reduce((p,c) => (p[c.shiftingDate] ? p[c.shiftingDate].push(c) : p[c.shiftingDate] = [c],p) ,{}),
        newData = Object.keys(hash).map(k => ({shiftingDate: k, value: hash[k]}));
        return newData;
      }

}